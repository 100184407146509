import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
// import ProcessOne from '../component/process/ProcessOne';
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
// import BrandItem from "../component/brand/BrandItem";
import BrandOne from "../component/brand/BrandOne";

const AboutUs = () => {
  return (
    <>
      <SEO title="About us" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="Empowering Progress, Inspiring Excellence"
          paragraph="We design and develop web and mobile applications for our clients worldwide."
          styleClass=""
          mainThumb="/images/banner/banner-thumb-4.png"
        />
        <AboutFour />
        <AboutThree />
        <BrandOne />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default AboutUs;
