import React from "react";
import { FaLightbulb, FaMoon } from "react-icons/fa";

const ColorSwitcher = () => {
  // useEffect(() => {
  //   // Set dark mode by default
  //   document.querySelector("body").classList.add("active-dark-mode");

  //   // Clean up function to remove the class when the component is unmounted
  //   return () => {
  //     document.querySelector("body").classList.remove("active-dark-mode");
  //   };
  // }, []);

  const switchColor = () => {
    document.querySelector("body").classList.toggle("active-dark-mode");
  };

  return (
    <div className="my_switcher d-none d-lg-block">
      <button onClick={switchColor}>
        <span className="setColor dark">
          <FaLightbulb />
        </span>
        <span className="setColor light">
          <FaMoon />
        </span>
      </button>
    </div>
  );
};

export default ColorSwitcher;
