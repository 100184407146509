import React from "react";
import { Link, useParams } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerTwo from "../elements/breadcrumb/BcrumbBannerTwo";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import { slugify } from "../utils";
import ProjectData from "../data/project/ProjectData.json";
// import VideoOne from '../component/video/VideoOne';
import Accordion from "react-bootstrap/Accordion";
import { FaCompress, FaCode, FaGlobe } from "react-icons/fa";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const allProjectData = ProjectData;

const ProjectDetails = () => {
  const params = useParams();
  const projectSlug = params.slug;

  const getProjectData = allProjectData.filter(
    (data) => slugify(data.title) === projectSlug
  );
  const detailsProject = getProjectData[0];

  return (
    <>
      <SEO title="Project Details" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerTwo
          title={detailsProject.title}
          paragraph={detailsProject.excerpt}
          // mainThumb={detailsProject.imageTwo}
        />
        <section className="section-padding single-portfolio-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="section-heading heading-left mb-0">
                  <span className="subtitle">
                    {detailsProject.category.map((cat, i) => (
                      <span key={i}>{cat}</span>
                    ))}
                  </span>
                  <h3 className="title">{detailsProject.title}</h3>
                </div>
                {detailsProject.body.map((para, index) => (
                  <p key={index} dangerouslySetInnerHTML={{ __html: para }}></p>
                ))}
                <Link
                  to={process.env.PUBLIC_URL + "/contact"}
                  className="axil-btn btn-fill-primary"
                >
                  Get it Now
                </Link>
              </div>
              <div className="col-lg-6 offset-xl-1">
                <div className="why-choose-us">
                  <div className="section-heading heading-left">
                    <h3 className="title">We delivered</h3>
                    <p>
                      Digital technology has made our world more transparent and
                      interconnected, posing new challenges and opportunities
                      for every business.
                    </p>
                  </div>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <FaCompress /> Strategy
                      </Accordion.Header>
                      <Accordion.Body>
                        Our strategy focuses on understanding user needs to
                        create a seamless and engaging experience. We prioritize
                        functionality and easy navigation, ensuring that our
                        website effectively represents our business and provides
                        comprehensive information about our services.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <FaCode /> Design
                      </Accordion.Header>
                      <Accordion.Body>
                        Our design approach emphasizes clarity, aesthetics, and
                        usability. We craft visually appealing, responsive
                        layouts with intuitive navigation, ensuring a
                        professional online presence that enhances user
                        confidence and engagement.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <FaGlobe /> Development
                      </Accordion.Header>
                      <Accordion.Body>
                        Our development process utilizes cutting-edge technology
                        for a fast, secure, and reliable website. We ensure
                        compatibility across devices and browsers, integrating
                        advanced features like online scheduling tools to
                        provide an interactive and efficient user experience.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <VideoOne /> */}
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ProjectDetails;
