import React from "react";

const Data = [
  {
    image: "/images/clients/1.svg",
  },
  {
    image: "/images/clients/2.svg",
  },
  {
    image: "/images/clients/3.svg",
  },
  {
    image: "/images/clients/4.svg",
  },
  {
    image: "/images/clients/5.svg",
  },
  {
    image: "/images/clients/6.svg",
  },
  {
    image: "/images/clients/7.svg",
  },
  {
    image: "/images/clients/8.svg",
  },
];

const BrandItem = () => {
  return (
    <>
      {Data.map((data, index) => (
        <div className="col-lg-3 col-6" key={index}>
          <div className="brand-grid">
            <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
          </div>
        </div>
      ))}
    </>
  );
};

export default BrandItem;
