import React from "react";
import {
  //   FaBehance,
  //   FaDribbble,
  //   FaFacebookF,
  FaFax,
  FaInstagram,
  //   FaLinkedin,
  FaPhone,
  //   FaPinterestP,
  //   FaTiktok,
  //   FaVimeoV,
} from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";

const FooterTwo = () => {
  return (
    <footer className="footer-area footer-dark">
      <div className="container">
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="https://gsoftsolution.com/">GSS</a>.
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-social-link">
                <ul className="list-unstyled">
                  {/* <li>
                    <Link to="https://facebook.com/">
                      <FaFacebookF />
                    </Link>
                  </li> */}
                  <li>
                    <a href="https://www.instagram.com/gss.dev">
                      <FaInstagram />
                    </a>
                  </li>
                  {/* <li>
                    <Link to="https://twitter.com/">
                      <FaXTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.linkedin.com/">
                      <FaLinkedin />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.pinterest.com/">
                      <FaPinterestP />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://vimeo.com/">
                      <FaVimeoV />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://dribbble.com/">
                      <FaDribbble />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.behance.net/">
                      <FaBehance />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.tiktok.com/en/">
                      <FaTiktok />
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <a href="tel:+355696820475" className="tel">
                      <FaPhone /> +355 69 682 0475
                    </a>
                  </li>
                  <li>
                    <a href="tel:+355688555608" className="tel">
                      <FaFax /> +355 68 855 5608
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterTwo;
