import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const AboutFour = () => {
  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured-thumb text-start">
              <img
                src={process.env.PUBLIC_URL + "/images/mockups/prendi.png"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle">Who we are</span>
                <h2 className="title">Building software for world changers</h2>
                <p>
                  At GSS, we specialize in empowering innovators and visionaries
                  with custom software solutions that drive transformative
                  change. Our dedicated team harnesses the power of technology
                  to solve real-world challenges, improve lives, and advance
                  industries.
                </p>
                <p>
                  By partnering with forward-thinking organizations, we bring
                  groundbreaking ideas to life, equipping them with the tools
                  needed to make a lasting impact. We build software for world
                  changers.
                </p>
                {/* <Link to="#" className="axil-btn btn-fill-primary btn-large">Read More</Link> */}
              </div>
              <div className="case-study-counterup">
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="3" duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">Years on the market</span>
                </div>
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="15" duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">
                    Projects delivered so far
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
