import React from "react";
// import { Link } from "react-router-dom";
import {
  // FaFacebookF,
  // FaPinterestP,
  // FaLinkedin,
  // FaInstagram,
  // FaVimeoV,
  // FaDribbble,
  // FaBehance,
  // FaTiktok,
  FaPhone,
  FaFax,
  FaInstagram,
} from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";
// import ServiceData from "../../data/service/ServiceMain.json";
// import { HashLink as Link } from "react-router-hash-link";

// const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        {/* <div className="footer-top">
          
        </div> */}
        {/* <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">Get in touch!</h2>
                  <p>
                    Whether you have questions about our services, want to
                    discuss a potential project, or just want to say hello,
                    don't hesitate to reach out. Our team is here to assist you
                    every step of the way."
                  </p>
                  <form>
                    <div className="input-group">
                      <span className="mail-icon">
                        <FaEnvelopeOpen />{" "}
                      </span>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                      />
                      <button className="subscribe-btn" type="submit">
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Services</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + `/services/#section1`}
                          >
                            Design
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + `/services/#section2`}
                          >
                            Development
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + `/services/#section3`}
                          >
                            Online Marketing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + `/services/#section4`}
                          >
                            Business
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + `/services/#section5`}
                          >
                            Technology
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Resourses</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          {" "}
                          <Link to={process.env.PUBLIC_URL + "/case-study"}>
                            Case Studies
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/project-grid-one"}
                          >
                            Portfolio
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Support</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/contact"}>
                            Contact
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                            Terms of Use
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="https://gsoftsolution.com/">GSS</a>.
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-social-link">
                <ul className="list-unstyled">
                  {/* <li>
                    <Link to="https://facebook.com/">
                      <FaFacebookF />
                    </Link>
                  </li> */}
                  <li>
                    <a href="https://www.instagram.com/gss.dev">
                      <FaInstagram />
                    </a>
                  </li>
                  {/* <li>
                    <Link to="https://twitter.com/">
                      <FaXTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.linkedin.com/">
                      <FaLinkedin />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.pinterest.com/">
                      <FaPinterestP />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://vimeo.com/">
                      <FaVimeoV />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://dribbble.com/">
                      <FaDribbble />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.behance.net/">
                      <FaBehance />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.tiktok.com/en/">
                      <FaTiktok />
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <a href="tel:+355696820475" className="tel">
                      <FaPhone /> +355 69 682 0475
                    </a>
                  </li>
                  <li>
                    <a href="tel:+355688555608" className="tel">
                      <FaFax /> +355 68 855 5608
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
