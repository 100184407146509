import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
            <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            {/* <div className="section-heading heading-left">
                                <span className="subtitle">About Us</span>
                                <h3 className="title">Custom Software Solutions to Simplify Your Workflow</h3>
                                <p>Custom software solutions streamline your business by addressing unique challenges, automating tasks, and enhancing communication. Our tailored services simplify your workflow, allowing you to focus on growth and innovation.</p>
                            </div> */}
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><FaCompress /> Strategy</Accordion.Header>
                                    <Accordion.Body>
                                    We analyze your business needs and collaborate with your team to design tailored software solutions. Focusing on flexibility and scalability, we use the latest technologies to create efficient, user-friendly systems that grow with your business.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><FaCode /> Design</Accordion.Header>
                                    <Accordion.Body>
                                    Custom software solutions streamline your business by addressing unique challenges, automating tasks, and enhancing communication. Our tailored services simplify your workflow, allowing you to focus on growth and innovation.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><FaGlobe /> Development</Accordion.Header>
                                    <Accordion.Body>
                                    Custom software development simplifies your business operations by tackling specific challenges, automating processes, and improving communication. Our bespoke solutions help you focus on growth and innovation.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                        <h3 className="title">Get a free Keystroke quote now</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;