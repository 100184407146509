import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Customer-Centric Approach",
        para: "We put our clients at the heart of everything we do, tailoring our solutions to meet their unique needs and exceed their expectations."
    },
    {
        id: 2,
        title: "Innovation and Creativity",
        para: "We embrace cutting-edge technologies and innovative thinking to deliver creative solutions that set our clients apart in their industries."
    },
    {
        id: 3,
        title: "Quality and Excellence",
        para: "Our commitment to high standards ensures that every project is executed with precision, resulting in reliable and superior software solutions."
    },
    {
        id: 4,
        title: "Transparency and Communication",
        para: "We maintain open and honest communication throughout the project lifecycle, ensuring our clients are informed and involved every step of the way."
    },
    {
        id: 5,
        title: " Collaboration and Teamwork",
        para: "We foster a collaborative environment where our team works closely with clients, encouraging shared ideas and joint problem-solving for optimal outcomes."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Valus"
                    title="Why should you work with us?"
                    description="At GSS, we are driven by a commitment to excellence, innovation, and integrity."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;